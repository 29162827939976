<template>
<div class="page">
    <div class="first-section">
        <video autoplay muted loop id="background-video">
            <source src="../assets/bg.mp4" type="video/mp4" />
            <!-- 提示用户视频不支持时显示的文本 -->
            Your browser does not support the video tag.
        </video>
        <!-- 添加半透明遮罩层 -->
        <div class="overlay"></div>
        <div class="logo">
            <img src="../assets/logo.png" alt="" />
        </div>
        <div class="overlay-text">
            <h1>ハイエンドのプライベート</h1>
            <p>速め通信<br />潤う関係の探索！</p>
            <van-button type="primary" round class="line-button" color="#F0013D" @click="randomRedirect">
                <template #icon>
                    <i class="fab fa-line"></i>
                </template>
                ご連絡下さい
            </van-button>
        </div>
        <div class="icon-arrow">
            <img src="../assets/chevrons-down.svg" alt="" />
        </div>
    </div>
    <div class="second-section">
        <div class="title">銃のステップ。</div>
        <img src="../assets/img-second.svg" width="100%" alt="">
        <div class="second-text">
            <div>
                LOVERは無料の男女マッチングプラットフォームであり、あらゆるレベルの男女マッチングサービスを提供していますこのプラットフォームは全国初のオフライン都市カバー率85%の厳格な会員制マッチングプラットフォームですあなたがどの地域にいても、私たちはプロフェッショナルなレベルで業界最高のサービスを提供することができます。
            </div>
            <div>以下を含む。</div>
            <div class="second-badge">全国の大砲。</div>
            <div class="second-badge">高品質の航空資源</div>
            <div class="second-badge">全ハイエンドのプライベートコンパニオン。</div>
            <div>私たちは<span class="hight-light">“誠実第一、サービス第一”</span>の経営理念を堅持し、業界リーダーになることを約束します。</div>
        </div>
    </div>
    <div class="third-section">
        <div class="title">メンバーの推薦</div>
        <div class="third-card">
            <div class="cover">
                <div class="vip-badge">
                    <img src="../assets/icon-vip.svg" alt="">
                    LOVER認定メンバー
                </div>
                <div class="vip-Distance">
                    LOVER認定メンバー
                </div>
                <img src="../assets/member-01.png" alt="">
            </div>
            <van-row style="margin-top: 16px;" justify="space-between">
                <van-col>
                    <div>空飛ぶドア / 刺激を求めて / 潮吹き噴霧</div>
                    <van-row style="margin-top: 8px;" gutter="20">
                        <van-col>24歳</van-col>
                        <van-col>162cm</van-col>
                        <van-col>Dカップ</van-col>
                    </van-row>
                </van-col>
                <van-col>
                    <van-button round hairline type="primary" size="small" class="third-card-button" @click="randomRedirect">私は彼女に</van-button>
                </van-col>
            </van-row>
        </div>

        <div class="third-card">
            <div class="cover">
                <div class="vip-badge">
                    <img src="../assets/icon-vip.svg" alt="">
                    LOVER認定メンバー
                </div>
                <div class="vip-Distance">
                    LOVER認定メンバー
                </div>
                <img src="../assets/member-02.png" alt="">
            </div>
            <van-row style="margin-top: 16px;" justify="space-between">
                <van-col>
                    <div>空飛ぶドア / 刺激を求めて / 潮吹き噴霧</div>
                    <van-row style="margin-top: 8px;" gutter="20">
                        <van-col>23歳</van-col>
                        <van-col>163cm</van-col>
                        <van-col>Dカップ</van-col>
                    </van-row>
                </van-col>
                <van-col>
                    <van-button round hairline type="primary" size="small" class="third-card-button" @click="randomRedirect">私は彼女に</van-button>
                </van-col>
            </van-row>
        </div>

        <div class="third-card">
            <div class="cover">
                <div class="vip-badge">
                    <img src="../assets/icon-vip.svg" alt="">
                    LOVER認定メンバー
                </div>
                <div class="vip-Distance">
                    LOVER認定メンバー
                </div>
                <img src="../assets/member-03.png" alt="">
            </div>
            <van-row style="margin-top: 16px;" justify="space-between">
                <van-col>
                    <div>空飛ぶドア / 刺激を求めて / 潮吹き噴霧</div>
                    <van-row style="margin-top: 8px;" gutter="20">
                        <van-col>22歳</van-col>
                        <van-col>166cm</van-col>
                        <van-col>Bカップ</van-col>
                    </van-row>
                </van-col>
                <van-col>
                    <van-button round hairline type="primary" size="small" class="third-card-button" @click="randomRedirect">私は彼女に</van-button>
                </van-col>
            </van-row>
        </div>

        <div class="third-card">
            <div class="cover">
                <div class="vip-badge">
                    <img src="../assets/icon-vip.svg" alt="">
                    LOVER認定メンバー
                </div>
                <div class="vip-Distance">
                    LOVER認定メンバー
                </div>
                <img src="../assets/member-04.png" alt="">
            </div>
            <van-row style="margin-top: 16px;" justify="space-between">
                <van-col>
                    <div>空飛ぶドア / 刺激を求めて / 潮吹き噴霧</div>
                    <van-row style="margin-top: 8px;" gutter="20">
                        <van-col>21歳</van-col>
                        <van-col>158cm</van-col>
                        <van-col>Dカップ</van-col>
                    </van-row>
                </van-col>
                <van-col>
                    <van-button round hairline type="primary" size="small" class="third-card-button" @click="randomRedirect">私は彼女に</van-button>
                </van-col>
            </van-row>
        </div>

        <van-button type="default" block round color="#F9E7D1" class="more-member-button" @click="randomRedirect">もっと見る。</van-button>

    </div>

    <div class="fourth-section">
        <div class="title">よく有る質問</div>
        <div class="fourth-card">
            <div class="question">
                <img src="../assets/icon-question.svg" alt="">
                <div>何が得られる？</div>
            </div>
            <div class="answer">
                <img src="../assets/icon-answer.svg" alt="">
                <div>長期的な関係、短期的な関係、または新しい興奮を探しているかどうか、LOVERでは理想的な親密な関係を見つけることができます。</div>
            </div>
            <div class="member-list">
                <div class="avatar-group">
                    <img src="../assets/avatar-01.png" alt="">
                    <img src="../assets/avatar-02.png" alt="">
                    <img src="../assets/avatar-03.png" alt="">
                </div>
                <div class="member-name">ほしの ゆうと <span>11682</span> ユーザーが確認した</div>
            </div>
        </div>

        <div class="fourth-card">
            <div class="question">
                <img src="../assets/icon-question.svg" alt="">
                <div>女性会員ってどんな人</div>
            </div>
            <div class="answer">
                <img src="../assets/icon-answer.svg" alt="">
                <div>彼らは、留学生、インターネットモデル、ファッショニスタなどの才能のある女の子であり、短期または長期のデート関係を探しています。彼らは忙しい仕事、小さな生活サークル、プライバシーと銃の品質に注意を払っています。
                </div>
            </div>
            <div class="member-list">
                <div class="avatar-group">
                    <img src="../assets/avatar-04.png" alt="">
                    <img src="../assets/avatar-05.png" alt="">
                    <img src="../assets/avatar-06.png" alt="">
                </div>
                <div class="member-name">かわさき りく<span>23451</span> ユーザーが確認した</div>
            </div>
        </div>

        <div class="fourth-card">
            <div class="question">
                <img src="../assets/icon-question.svg" alt="">
                <div>良質な女性会員を選ぶには？</div>
            </div>
            <div class="answer">
                <img src="../assets/icon-answer.svg" alt="">
                <div>すべての女の子は招待制で入会するだけでなく、二要素認証：実在のLINE認証と実際のナンパ需要検証も必要です。

                    執事の心のこもったサービスは数万人の顔立ちの良い優良女性会員を虜にした。執事はあなたのニーズに応じて正確に推薦し、左右に時間を無駄にしないようにします。

                    Loverは女の子を気遣うプラットフォームであり、女の子を尊重しない男の子はここでは歓迎されません。執事は毎回ペアを組んだ後、相互評価機制もあり、他人の利益を怒らせたり損なったりすると厳しく処罰されます。

                    同時に、Loverはユーザー間で直接取引と経済往来を厳しく禁止し、通報された場合、直ちにプラットフォームから追い出されます。</div>
            </div>
            <div class="member-list">
                <div class="avatar-group">
                    <img src="../assets/avatar-07.png" alt="">
                    <img src="../assets/avatar-08.png" alt="">
                    <img src="../assets/avatar-09.png" alt="">
                </div>
                <div class="member-name">あおき あお <span>36957</span> ユーザーが確認した</div>
            </div>
        </div>

        <div class="fourth-card">
            <div class="question">
                <img src="../assets/icon-question.svg" alt="">
                <div>サービスの範囲？</div>
            </div>
            <div class="answer">
                <img src="../assets/icon-answer.svg" alt="">
                <div>日本だけでなく、Loverは海外留学界でも知名度が高く、全世界で20万人以上の優良会員がいます。</div>
            </div>
            <div class="member-list">
                <div class="avatar-group">
                    <img src="../assets/avatar-10.png" alt="">
                    <img src="../assets/avatar-11.png" alt="">
                    <img src="../assets/avatar-12.png" alt="">
                </div>
                <div class="member-name">さとう こう <span>18523</span> ユーザーが確認した</div>
            </div>
        </div>

        <div class="fourth-card">
            <div class="question">
                <img src="../assets/icon-question.svg" alt="">
                <div>Loverのアプリはなぜ有料で使えるのですか？</div>
            </div>
            <div class="answer">
                <img src="../assets/icon-answer.svg" alt="">
                <div>Lover
                    Appはもともと無料で使用できますが、現在Loverに加入したい人が多すぎるため、カスタマーサービスの仕事のストレスを軽減するために、また、もともと資料が不合格または純粋に好奇心旺盛なユーザーを説得するために、Lover
                    Appは2021年6月から低価格の入場料を設定し始めた。有料ユーザーのみがすべての会員資料を確認する資格があり、適切と思われる場合はVIPレベルの会員を申請し、正式にLoverコミュニティに参加し、すべての会員に責任を持ち、最高品質のカスタマイズサービスを提供します。
                </div>
            </div>
            <div class="member-list">
                <div class="avatar-group">
                    <img src="../assets/avatar-13.png" alt="">
                    <img src="../assets/avatar-14.png" alt="">
                    <img src="../assets/avatar-15.png" alt="">
                </div>
                <div class="member-name">ふじた けいすけ <span>18197</span> ユーザーが確認した</div>
            </div>
        </div>

    </div>
    <div class="fifth-section">
        <van-row gutter="16">
            <van-col span="12">
                <img src="../assets/img-xieyi.svg" width="100%" alt="" @click="this.showxieyi = true">
            </van-col>
            <van-col span="12">
                <img src="../assets/img-tiaokuan.svg" width="100%" alt="" @click="this.showtiaokuan = true">
            </van-col>
        </van-row>
    </div>

    <div class="fixed-footer" v-show="showFooter">
        <van-row justify="space-between" gutter="16" style="padding: 0 16px;">
            <van-col>
                <img src="../assets/logo.png" class="footer-logo" alt="">
            </van-col>
            <van-col>
                <van-button type="primary" round class="line-button" color="#F0013D" size="small" @click="randomRedirect">
                    <template #icon>
                        <i class="fab fa-line"></i>
                    </template>
                    ご連絡下さい
                </van-button>
            </van-col>
        </van-row>
    </div>

    <van-action-sheet v-model:show="showxieyi" title="ユーザー契約">
        <div class="mocalcontent">
            <p>安全デート</p>
            <p>Loverサイトは人々を集めます。毎日多くの人がここで知り合い、私たちのコミュニティは絶えず発展し、成長しています。Loverで友達を作るには、ユーザーの安全が最優先です。私たちは、初めての出会いや出会いが面白くて刺激的であることを理解しています。しかし、あなたの安全は非常に重要です。そして、あなたは自分の行動を最もコントロールできるので、デートするときは、オンラインでもオフラインでも、いくつかの安全手順に従うべきです。以下のヒントと情報をお読みいただき、お客様の身の安全と利益のためにこれらのガイドラインに従うことを強くお勧めします。しかし、あなた自身こそ自分の安全を最も判断し、自分の行動に責任を持つことができる人です。私たちが提示したこれらの指針は、あなた自身の判断に取って代わるものではなく、あなたのためにすべてのリスクを完全に回避することも保証できません。
            </p>
            <p>個人の財産の安全を守る</p>
            <p>あなたの財産の安全を守り、決して他人にお金を貸したり、送金したり、個人の財務情報を漏らしたりしないでください。いかなる送金要求も決して受け入れないでください、特に電信送金、ネットバンキングの形式を通じて。発見したらすぐに私たちに報告してください。ネット詐欺は時々発生し、取引の取り消しや資金の回収はほぼ不可能です。緊急状態にあり、資金援助が必要だと主張する人がいても、いかなる送金要求も承諾しないでください。
            </p>
            <p>
                あなたの個人情報を守る</p>
            <p>名前、身分証明書番号、銀行情報、または職場や自宅の住所など、知らない人や直接会ったことのない人に個人情報を提供しないでください。注意：Loverは決してあなたにメールを送り、ユーザー名とパスワードの情報を要求しません。このような通信は直ちに報告しなければならない。
            </p>
            <p>ネットで賢く友達を作る</p>
            <p>不審なユーザーを阻止し、通報する。あなたはいつでもLoverで匿名で疑わしいユーザーの行為を阻止し、報告することができます。私たちはユーザーが積極的に不良行為を報告することを望んでいます。当社の利用規約に違反する行為には以下が含まれます：
            </p>
            <p>
                お金、借り入れ、または寄付金を要求する</p>
            <p>写真を撮るように要求する</p>
            <p>未成年者が本プラットフォームを利用する</p>
            <p>ユーザーは嫌がらせや攻撃的なメッセージを送る</p>
            <p>虚偽の個人情報</p>
            <p>声明</p>
            <p>このウェブサイトはセックスワーカーの使用を厳禁しています</p>
            <p>Lover交友プラットフォームは決してセックスワーカーとポルノサービススタッフのために作られたものではない
            </p>
            <p>サイト内の個人情報はLoverスタッフによって識別され、追放され、ブロックされます。
            </p>
        </div>
    </van-action-sheet>

    <van-action-sheet v-model:show="showtiaokuan" title="プライバシー条項">
        <div class="mocalcontent">
            <p>本サイトが提供する各機能を使用する前に、必ず本プライバシー条項をよくお読みください。条項に関する内容に同意しない場合は、直ちに本サイトが提供する機能の使用を停止することができます。本サイトが提供する機能を登録して使用すると、プライバシー条項の各内容を詳細に読み、完全に理解したとみなされます。</p>
            <p>1.このウェブサイトが提供する各機能を使用する過程で、私たちはあなたの個人情報を必要な保護にもかかわらず、インターネットとあなたの電子メールを通じて提供した情報の安全性と機密性を保証できないことを認めなければなりません。私たちのプライバシー条項は参考ファイルを通じて条項に組み込まれています。あなたは、当社のウェブサイトおよび責任者がこのような論争によって引き起こされた、またはそれに関連する、既知または未知、直接または偶然、開示と未開示、このような情報の発表または使用によって引き起こされた、またはそれに関連する、第三者が提出したすべての請求、要求、損害賠償、損失、債務および紛争を免除することに同意するものとします。違法侵害などの行為。インターネットと情報システムの使用の固有の不確実性、および第三者の違法攻撃の潜在的な可能性により、当社のシステム、ウェブサイトまたはアプリケーションの使用が絶対的に適切で安全であることを保証できないことを確認し、理解しています。私たちは、カナダにある、または私たちが機器またはホストサービスを維持または使用している他の国にある他の機器にあなたの個人情報を処理し、保存することがあります。情報は、合法的な命令または法的手続きに基づく法執行機関または他の政府機関によってアクセスされる可能性があります。あなたが私たちに提供したいかなる個人情報も、すべての適用法に拘束される可能性があります。</p>
            <p>2.ユーザーが本サイトのプラットフォームアカウントを登録すると、お名前、ニックネーム、メールアドレス、生年月日、性別、職業、所属業界、個人収入などの一連の個人情報を尋ねます。当サイトのプラットフォームに登録し、サーバーにログインすれば、あなたを識別できます。このウェブサイトは、あなたのIPアドレス、オンライン、無線情報などの資料を含むが、これに限定されない、あなたのブラウザとサーバーログ上の情報を自動的に受け取り、記録します。このウェブサイトはこれらの資料を以下に使用します：提供したウェブページの内容を改善し、ある製品、イベントに対するあなたの需要を満たしたり、最新の製品、イベント情報をお知らせします。</p>
            <p>3.ユーザーがLover交友プラットフォームのアカウントを登録し、他のLover交友プラットフォームの製品やサービスを使用し、Lover交友プラットフォームのウェブページにアクセスしたり、あらゆる形態の会員活動に参加したりする場合、Lover交友プラットフォームはあなたの個人識別情報を収集します。Lover交友プラットフォームもパートナーからの身分識別資料を収集します。</p>
            <p>4.Lover交友プラットフォームは、あなたのIPアドレス、オンライン、無線情報などの資料を含むが、これに限定されない、ブラウザとサーバーのログ上の情報を自動的に受け取り、記録します。Lover交友プラットフォームは、これらの資料を以下に使用します：あなたに提供したウェブページの内容を改善し、ある製品、イベントに対するあなたの需要を満たしたり、最新の製品、イベント情報をお知らせします。</p>
            <p>5.あなたは、あなたのウェブサイトサービスの公開使用エリア及びウェブサイトサービスのその他の公開使用エリアに掲示された内容、または個人情報、連絡方法、写真、グラフィックまたは映像資料などの内容を含め、Lover交友プラットフォームにグローバル、ライセンス料無料及び非独占的な使用権を付与することに同意するものとします。Lover交友プラットフォームは、前述の内容を展示、拡散、宣伝掲示する特定のサービス目的のために、前述の内容を複製、修正、書き換え、改作または出版することができます。前述の内容をウェブサイトサービスに入れる間、使用権は持続的に有効です。前述の内容をウェブサイトサービスから削除した場合、使用権は削除時に終了します。</p>
            <p>6.あなたは、個人情報、連絡方法、写真、図形または映像資料などの内容を含む、ウェブサイトサービスの他の公開使用エリアに掲示されたその他の内容について、Lover交友プラットフォームにライセンス料無料、永久有効、取消不能、非独占および完全に再授権可能な権利をグローバルで使用、複製することに同意するものとします。、修正、書き換え、改作、発行、翻訳、派生的な著作の作成、または前述の内容（一部または全部）を拡散、展示、または現在知られている形式、メディア、科学技術を利用する他の著作物に入れる。</p>
            <p>7.Lover交友プラットフォームはツールを利用して、パートナーのウェブサイトのデータ収集作業を行い、関連データは統計用途にも使用されます。ウェブサイトは記録されたLover交友プラットフォームの会員データを統合し、総合データ形式でパートナーの参考に供する。総合データには人数の統計と使用状況などの資料が含まれますが、個人の身分を識別できるデータは一切含まれません。Lover交友プラットフォームがパートナーのためにデータ収集を行う場合、パートナーと同時にウェブサイトまたはウェブページでユーザーに通知します。
            </p>
            <p>8.Lover交友プラットフォームは、ウェブサイトサービスが保持する電子メールメッセージ、掲示された内容または他のアップロード内容の最長期間、アカウントごとに送受信できる電子メールメッセージの最大数および送受信可能な単一のメッセージのサイズを含むが、これらに限定されない一般的な措置と制限を制定する権利があります。ウェブサイトサービスを通じて保存または転送された情報、通信資料、その他の内容が削除または保存されていない場合、Lover交友プラットフォームは一切の責任を負いません。また、長い間使用していないアカウントは、Lover交友プラットフォームを閉鎖する権利があることに同意します。また、Lover交友プラットフォームは、通知の有無にかかわらず、いつでもこれらの一般的な措置と制限を変更する権利があります。</p>
            <p>9.Lover交友プラットフォームは、あなたの個人識別情報を誰にも貸したり売ったりしません。以下の場合を除く：a.あなたは第三者に資料を共有することに同意します b.あなたの個人情報を開示してこそ、あなたが要求した製品とサービスを提供することができます c.あなたの生命、身体または財産に関する差し迫った危険を免れるため；他人の権益の重大な危害を防ぐため d.私たちは私たちに代わって製品やサービスを提供する会社に資料を提供する必要があります（私たちが別途通知しない限り、これらの会社はあなたの身元確認資料を使用する権利がありません）e.私たちは裁判所の召喚状、法律命令に従うか、法的手続きに従う必要がありますf.私たちはあなたがLover交友プラットフォームのサービス規約または三十一日ネットワークテクノロジー株式会社のその他のサービスの使用規定に違反していることを発見しました</p>
            <p>10.あなたのLover交友プラットフォームアカウントにはパスワード保護機能があり、あなたのプライバシーと情報セキュリティを確保します。場合によっては、Lover交友プラットフォームは通行標準の機密システムを使用して、資料転送の安全性を確保します。</p>
            <p>11.個人アカウント資料の編集と削除（つまりサービス終了）の権限 Lover交友プラットフォームは、サービス期間中いつでもLover交友プラットフォームのアカウント情報を編集する権利を与えます。ユーザーまたはLover交友プラットフォームは、実際の状況に応じていつでもサービスを中断することができます。ユーザーがいかなるサービス規約の提案に反対したり、その後の条項の修正に異論がある場合、またはLover交友プラットフォームサービスに不満がある場合、ユーザーは以下の追索権しかない：a.Lover交友プラットフォームサービスはもう使用しません。B.ユーザーがLover交友プラットフォームサービスを利用する資格を終了します。C。Lover交友プラットフォームにこのユーザーのサービスを停止するよう通知する。ユーザーサービスを終了した後、ユーザーがLover交友プラットフォームサービスを利用する権利は直ちに終了します。それ以来、Lover交友プラットフォームはユーザーに対していかなる義務も負いません。</p>
            <p>12.Lover交友プラットフォームはプライバシーポリシーを修正する権利があります。ユーザーの個人情報ポリシーの使用に関して大幅な修正があった場合、私たちはウェブページの目立つ位置に関連規定を掲示し、ユーザーにタイムリーに通知します。他に質問や提案があれば、直接カスタマーサービスセンターに連絡してください。</p>

        </div>
    </van-action-sheet>

</div>
</template>

<script>
export default {
    name: 'HomePage',
    props: {
        msg: String,
    },
    data() {
        return {
            showFooter: false,
            showxieyi: false,
            showtiaokuan: false,
            //此处为页面的跳转链接，如果有增加，直接按格式往下复制即可
            links: [
                'https://line.me/ti/p/fesYX1EP_H',
                'https://line.me/ti/p/xGUzq4jobE',
                'https://line.me/ti/p/DHo_MEgzxd',
                'https://line.me/ti/p/A8Mmpmqqny'
            ],
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        // 初始化时检查滚动位置
        this.handleScroll();
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        handleScroll() {
            const scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop ||
                0;
            // 当滚动距离为 0（页面在顶部）时，隐藏底栏
            this.showFooter = scrollTop > 0;
        },
        randomRedirect() {
            const randomIndex = Math.floor(Math.random() * this.links.length);
            const randomLink = this.links[randomIndex];
            window.location.href = randomLink;
        },
    },
};
</script>

<style scoped>
.page{
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  
}
::v-deep .van-action-sheet{
  width: 100%;
  max-width: 500px !important;
  left: 50%;
  margin-left: -250px;
}
.first-section {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.first-section .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.first-section #background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 1;
    background-size: cover;
}

.first-section .logo {
    position: absolute;
    z-index: 2;
    top: 2em;
    left: 2em;
}

.first-section .logo img {
    width: 150px;
}

.first-section .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 2;
    width: 80%;
}

.first-section .overlay-text h1 {
    font-size: 2.3em;
    margin: 0;
}

.first-section .overlay-text p {
    font-size: 32px;
    font-weight: 200;
    line-height: 44.383px;
}

.first-section .icon-arrow {
    position: absolute;
    top: 80%;
    left: 50%;
    margin-left: -10px;
    color: white;
    text-align: center;
    z-index: 2;
    animation: homepage-float 2s ease-in-out infinite;
}

.second-section {
    background: url(../assets/bg-section2.png) center center no-repeat;
    background-size: cover;
    padding: 24px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.second-section .title {
    position: relative;
    font-size: 24px;
    z-index: 1;
    /* 确保文字在下划线上方 */
    margin-bottom: 16px;
    display: inline-block;
    /* 让标题宽度根据内容自适应 */
}

.second-section .title::after {
    content: '';
    position: absolute;
    left: 0;
    width: 80%;
    /* 调整下划线宽度，例如80% */
    height: 6px;
    /* 下划线的高度 */
    background-color: #F0013D;
    /* 下划线颜色 */
    opacity: 0.2;
    z-index: -1;
    /* 确保下划线在文字下方 */
    bottom: -2px;
    /* 控制下划线位置 */
    transform: translateX(10%);
    /* 根据需求调整位置，使下划线不全宽 */
}

.second-section .second-text {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.10);
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    margin-top: 16px;
}

.second-section .second-badge {
    display: flex;
    height: 24px;
    padding: 0px 6px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 10px 10px 10px 1px;
    background: #F0013D;
    color: #Fff;
    line-height: 24px;
}

.second-section .hight-light {
    color: #F0013D;
}

.third-section {
    background: #313131;
    color: #FDE7CF;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.third-section .title {
    position: relative;
    font-size: 24px;
    z-index: 1;
    /* 确保文字在下划线上方 */
    margin-bottom: 16px;
    display: inline-block;
    /* 让标题宽度根据内容自适应 */
}

.third-section .title::after {
    content: '';
    position: absolute;
    left: 0;
    width: 80%;
    /* 调整下划线宽度，例如80% */
    height: 6px;
    /* 下划线的高度 */
    background-color: #F0013D;
    /* 下划线颜色 */
    opacity: 0.2;
    z-index: -1;
    /* 确保下划线在文字下方 */
    bottom: -2px;
    /* 控制下划线位置 */
    transform: translateX(10%);
    /* 根据需求调整位置，使下划线不全宽 */
}

.third-section .third-card {
    width: 100%;
    /* height: 188px; */
    position: relative;
    font-size: 12px;
    margin-bottom: 16px;
}

.third-section .third-card .cover {
    width: 100%;
    /* height: 188px; */
    position: relative;
}

.third-section .third-card .cover .vip-badge {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    height: 22px;
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 8px 0px;
    background: rgba(0, 0, 0, 0.70);
}

.third-section .third-card .cover .vip-badge img {
    width: 12px;
    height: 12px;
}

.third-section .third-card .cover .vip-Distance {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 6px;
    border-radius: 8px 0px;
    background: rgba(0, 0, 0, 0.70);
}

.third-section .third-card img {
    width: 100%;
    height: 100%;
}

.third-section .third-card .third-card-button {
    background: none;
    border-color: #FDE7CF;
    color: #FDE7CF;
    padding: 3px 8px;
}

.third-section .more-member-button {
    color: #8F6A2E !important;
}

.fourth-section {
    background: #313131;
    color: #FDE7CF;
    padding: 16px 16px 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
}

.fourth-section .title {
    position: relative;
    font-size: 24px;
    z-index: 1;
    /* 确保文字在下划线上方 */
    margin-bottom: 16px;
    display: inline-block;
    /* 让标题宽度根据内容自适应 */
}

.fourth-section .title::after {
    content: '';
    position: absolute;
    left: 0;
    width: 80%;
    /* 调整下划线宽度，例如80% */
    height: 6px;
    /* 下划线的高度 */
    background-color: #F0013D;
    /* 下划线颜色 */
    opacity: 0.2;
    z-index: -1;
    /* 确保下划线在文字下方 */
    bottom: -2px;
    /* 控制下划线位置 */
    transform: translateX(10%);
    /* 根据需求调整位置，使下划线不全宽 */
}

.fourth-card {
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--Colors-Base-100, #000);
    margin-bottom: 16px;
}

.fourth-card .question {
    display: flex;
    align-items: center;
    gap: 4px;
}

.fourth-card .answer {
    display: flex;
    align-items: flex-start;
    gap: 4px;
}

.fourth-card .member-list {
    display: flex;
    padding: 4px 8px 4px 4px;
    align-items: center;
    gap: 10px;
    border-radius: 34px;
    background: #313131;
}

.fourth-card .member-list .avatar-group {
    display: flex;
    align-items: flex-start;
    padding-right: 10px;
}

.fourth-card .member-list .avatar-group img {
    width: 24px;
    height: 24px;
    margin-right: -10px
}

.fourth-card .member-list .member-name {
    font-size: 12px;
    display: flex;
    gap: 8px
}

.fourth-card .member-list .member-name span {
    color: #F0013D;
}

.fifth-section {
    background: #313131;
    color: #FDE7CF;
    padding: 0 16px 66px 16px;
}

.fixed-footer {
    position: fixed;
    bottom: 0;
    left: 50%;
    margin-left: -250px;
    width: 100%;
    max-width: 500px;
    /* 占满页面宽度 */
    background-color: #000;
    /* 背景颜色 */
    color: white;
    /* 字体颜色 */
    text-align: center;
    /* 文字居中 */
    padding: 10px 0;
    /* 内边距 */
    z-index: 1000;
    /* 确保它在最上层 */
}

.fixed-footer .footer-logo {
    width: 67px;
}

.mocalcontent {
    padding: 16px;
    font-size: 14px;
    line-height: 22px;
}
</style>

<style>
@keyframes homepage-float {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}
</style>
